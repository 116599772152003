import { render, staticRenderFns } from "./default.vue?vue&type=template&id=87b48bc8"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreAppBar: require('/var/www/html/dev_sitrendi/fe/components/core/AppBar.vue').default,CoreDrawer: require('/var/www/html/dev_sitrendi/fe/components/core/Drawer.vue').default,CoreView: require('/var/www/html/dev_sitrendi/fe/components/core/View.vue').default,CoreFooter: require('/var/www/html/dev_sitrendi/fe/components/core/Footer.vue').default})
